.quote__banner{
    /* background: #2e2e2e; */
    background: rgba(0, 0, 0, 0.9);
    width:100%;
    height: 10%; 
    position: fixed;
    z-Index: 20; 
    bottom: 0px;
    border-top: 4px solid #db9e36;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.call__text{
    color: #db9e36;
    font-weight: bold;
}

.callus a{
    text-decoration: none;
    color: #db9e36;
    font-weight: bold;
}

.callus a:hover{
    text-decoration: none;
    color: white;
}



@media only screen and ((max-width: 768px)){
    .quote__banner{
        height: 15%;
    }

    .callusbtn{
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }

    p.call__text{
        margin-bottom: 0.5rem;
    }
}

@media only screen and (max-width: 390px){
    .quote__banner{
        height: 20%;
    }

    .callusbtn{
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }

    p.call__text{
        margin-bottom: 0.5rem;
    }
}