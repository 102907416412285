.footer{
    padding-top: 70px;
    padding-bottom: 30px;
}

.footer .logo img{
    width: 50%;
    margin-bottom: 1rem;
}

.footer .logo p{
    color: var(--text-color);
}

.social__links span a{
    text-decoration: none;
    color: var(--heading-color);
    font-size: 1.2rem;
}

.footer__links-title{
    color: var(--heading-color);
    margin-bottom: 1rem;
}

.footer__quick-links li a{
    text-decoration: none;
    color: var(--text-color);
    font-size: 1.1rem;
}

.footer__quick-links h6 span i{
    color: var(--secondary-color);
    font-size: 1.1rem;
}

.copyrights{
    color: var(--text-color);
    font-size: 1rem;
    position: relative;
    margin-bottom: 3rem;
    background-color: rgba(202, 229, 244, 0.21);
}


@media only screen and (max-width: 992px) {
    .logo img {
        width: 30% ;
    }
}