.header {
    width: 100%;
    position: relative;
    background-color: #2e2e2e;
    padding-top: 0.25rem;
    z-index: 999;
}

.headerView {
    width: 100%;
    background: #2e2e2e;
    position: fixed;
    top: 0;
    left: 0;
    box-shadow: 3px 3px 8px -3px #ddd;
    z-index: 999;
    /* display: none; */
    opacity: 0;
    transition: opacity 0s ease;
    box-shadow: 3px 3px 8px -3px #ddd;
    z-index: 999;
}

.headerView:hover {
    opacity: 1;
}

.logo img {
    width: 50%;
    padding-bottom: 5px;
}

.menu {
    margin-bottom: 0;
}

.nav__item a {
    text-decoration: none;
    /* color: var(--heading-color); */
    color: white;
    font-weight: 500;
    font-size: 1.1rem;
}

.secondary__btn a {
    color: white;
}

.nav__item a:hover {
    /* color: inherit; */
    color: #db9e36;
}

.nav__item a.active__link {
    color: var(--secondary-color);
}

.mobile__menu {
    font-size: 1.3rem;
    color: var(--heading-color);
    display: none;
}

.sticky__header {
    width: 100%;
    /* height: 80px; */
    /* line-height: 80px; */
    /* background: #fff; */
    background: #2e2e2e;
    position: sticky;
    top: 0;
    left: 0;
    box-shadow: 3px 3px 8px -3px #ddd;
    z-index: 999;
}

.secondary__btn a:hover {
    color: #db9e36;
}

.goog-te-gadget-simple {
    border-radius: 50px;
    font-size: inherit;
}

.goog-te-gadget {
    line-height: 35px;
}

/* ===========Make it Responsive ============= */

@media only screen and (max-width: 992px) {
    .logo img {
        width: 20%;
    }

    .navigation {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.411);
        z-index: 999;
        display: none;
    }

    .menu {
        position: absolute;
        top: 0;
        right: 0;
        width: calc(100% - 75%);
        height: 100%;
        background: #2e2e2e;
        flex-direction: column;
        line-height: 0.5rem;
        justify-content: center;
        z-index: 9999;
    }

    .show__menu {
        display: block;
    }

    .headerView {
        opacity: 1;
    }

    .mobile__menu {
        display: block;
    }
}

@media only screen and (max-width: 768px) {

    .menu {
        width: calc(100% - 50%);
    }

    .goog-te-gadget {
        line-height: 25px;
    }

    .logo img {
        /* width: 40% !important; */
    }
}


@media only screen and (max-width: 576px) {
    .goog-te-gadget {
        line-height: 26px;
    }

    .logo img {
        /* width: 100%; */
    }
}

@media only screen and (max-width: 520px) {
    .logo img {
        width: 70px !important;
    }
}

@media only screen and (max-width: 425px) {
    .goog-te-gadget {
        line-height: 24px;
    }
}

@media only screen and (max-width: 375px) {

    .gap-4 {
        gap: 0.6rem !important;
    }
}


.ri-menu-line:before {
    color: white;
}