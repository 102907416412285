.heroSection-about {
    background: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)),
        url('../assets/images/about.jpg') no-repeat center center;
    background-size: cover;
    height: 70vh;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.heroSection-about h1 {
    font-size: 4rem;
    padding-bottom: 1rem;
    color: white !important;
    font-family: cursive;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
    text-align: center;
}

.styled-list-about {
    list-style: disc inside;
    padding-left:1rem;
    font-size: 1.1rem;
    font-weight: bold;
}

.heroSection-about h5 {
    padding-bottom: 1rem;
    color: rgb(233, 228, 212) !important;
    font-family: cursive;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
}

.hrBlue {
    width: 25%;
    border: 1.5px solid #D8E8DE;
}

.hrOrange {
    width: 50%;
    border: 1.5px solid #db9e36;
    /* margin-left: auto; */
}

.smallHighlight {
    color: var(--secondary-color);
    font-weight: 500;
}

.highlight {
    font-weight: 700;
    font-family: var(--mea-font-name);
    color: var(--secondary-color);
}

.vertical-line {
    width: 1px;
    /* Width of the line */
    height: 100px;
    /* Height of the line */
    background-color: black;
    /* Color of the line */
}


.grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}

.grid-item {
    background-color: #ddd;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
}

.grid-container .first {
    background: linear-gradient(rgba(0.80, 0.80, 0.80, 0.80), rgba(0, 0, 0, 0.60)),
        url('../assets/images/laptop.jpeg') no-repeat center center;
}

.grid-container .second {
    background: linear-gradient(rgba(0.80, 0.80, 0.80, 0.80), rgba(0, 0, 0, 0.60)),
        url('../assets/images/win.jpeg') no-repeat center center;
}

.grid-container .third {
    background: linear-gradient(rgba(0.80, 0.80, 0.80, 0.80), rgba(0, 0, 0, 0.60)),
        url('../assets/images/engaged.webp') no-repeat center center;
}

.grid-container .fourth {
    background: linear-gradient(rgba(0.80, 0.80, 0.80, 0.80), rgba(0, 0, 0, 0.60)),
        url('../assets/images/connect.webp') no-repeat center center;
}

.grid-container .fourth,
.grid-container .third,
.grid-container .second,
.grid-container .first {
    background-size: cover;
    height: 30vh;
    width: 100%;
    color: #fff;
    border: 1px solid white;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: start;
}

.grid-container h1 {
    color: white;
}


@media only screen and (max-width: 630px) {
.grid-container .fourth,
.grid-container .third,
.grid-container .second,
.grid-container .first {
    height: 20vh;
    width: 25vh;
}
.grid-container .grid-item h1{
    font-size: 1.5rem;
    text-align: center;
}

}