.carousel-caption {
    color: white;
    bottom: 5rem !important;
    font-weight: bolder;

    text-shadow: 2px 2px var(--secondary-color);
}

.carousel-caption h3 {
    color: white;
    font-size: 3em;
}

.carousel-caption h3 {
    color: white;
    font-size: 3em;
}

.buttonBookNow {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    box-shadow: rgba(0, 0, 0, 0.55) 0px 15px 15px;
    width: 20% !important;
  }

@media (max-width: 768px) {
    .carousel-item img {
        height: 90% !important;
    }
    .buttonBookNow{
        width: 50% !important;
    }
}

