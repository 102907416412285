.pageTitle {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    text-shadow: 2px 2px black;
}

.pageTitle h1,
.pageTitle h4 {
    width: 100% !important;
    color: white !important;
    font-weight: bolder !important;
}

.booking-page {
    margin: 1vh 7vw 1vh 7vw;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;  
    height: 100%;
    align-items: stretch;
    justify-content: stretch;
}

.booking__form-page,
.tour__info-page {
    /* flex: 1; */
    /* Equal distribution of space */
    display: flex;
    flex-direction: column;
    /* margin-left: 1rem;
    margin-right: 1rem; */
    /* height: 100%; */
    width: 100%;
    margin-bottom: 2vh;
}

.booking__top-page h3 {
    font-size: 2rem;
    font-weight: 700;
}

.booking__top-page h3 span {
    font-size: 1.1rem;
    font-weight: 400;
    color: var(--text-color);
}

.booking__top-page span {
    font-size: 1.1rem;
    font-weight: 600;
    color: var(--text-color);
}

.booking__top-page span i {
    color: var(--secondary-color);
}

.booking__top-page {
    padding-bottom: 2rem;
    border-bottom: 1px solid rgb(229, 231, 235);
}

.booking__form-page {
    flex: 3;
    background-color: #D8E8DE;
    padding: 2rem;
    border-radius: .5rem;
    border: 1px solid rgb(229, 231, 235);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.booking__form-page h5 {
    margin-bottom: 1rem;
}

.booking__form-page input {
    width: 100%;
    padding: 0.5rem;
    border-radius: 0.5rem;
    color: var(--heading-color);
    font-style: 1rem;
    border: none;
    border-bottom: 1px solid rgb(229, 231, 235);
}

.booking__form-page input:focus {
    outline: none;
}

.booking__info-form-page {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* border: 1px solid rgb(229, 231, 235); */
}

.booking__bottom-page li {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.primary__btn-page {
    color: white !important;
    background-color: #db9e36 !important;
    border-color: #db9e36 !important;
    font-weight: bold;
}

.booking__bottom-page li h5,
.booking__bottom-page li span {
    font-size: 1rem;
    color: var(--text-color);
    font-weight: 500;
}

.booking__bottom-page {
    margin-top: 1rem;

}

.total-page h5,
.total-page span {
    color: var(--heading-color) !important;
    font-weight: 700 !important;
    font-size: 1.1rem !important;
}


.tour__content-page img {
    width: 100%;
    border-radius: .5rem;
    margin-bottom: 2.5rem;
}

.tour__info-page {
    height: 100%;
    flex: 2;
    width: 100%;
    border: 1px solid rgb(229, 231, 235);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.tour__info-page,
.tour__reviews-page {
    border-radius: .5rem;
    border: 1px solid rgb(229, 231, 235);
    padding: 2rem;
    /* width: 80vh; */
}

.tour__info-page h2 {
    font-size: 1.5rem;
}

.tour__info-page span {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    font-style: 1rem;
    color: var(--text-color);
}

.tour__info-page span i {
    color: var(--heading-color);
    font-size: 1.1rem;
}

.tour__extra-details-page {
    align-items: center;
    column-gap: 2.7rem;
    margin-top: 1rem;
    margin-bottom: 2.5rem;
}

.tour__info-page h5 {
    margin-bottom: 1rem;
    font-size: 1.3rem;
}

.tour__info-page p {
    line-height: 30px;
    font-size: 1rem;
    color: var(--text-color);
}

.rating__group-page span {
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    color: var(--secondary-color);
    cursor: pointer;
}

.tour__reviews-page h4 {
    margin-bottom: 3rem;
}

.review__input-page {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0.7rem;
    border-radius: 2rem;
    border: 1px solid var(--secondary-color);
}

.review__input-page input {
    width: 100%;
    padding: 0.5rem 0;
    border: none;
}

.review__input-page input:focus {
    outline: none;
}

.review__item-page {
    display: flex;
    align-items: center;
    column-gap: 1rem;
    margin-bottom: 2rem;
}

.review__item-page img {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50px;
    object-fit: cover;
}

.user__reviews-page {
    margin-top: 2.5rem;
}

.review__item-page h5 {
    font-size: 1rem;
    margin-bottom: 0;
}

.review__item-page p {
    font-size: .9rem;
    color: var(--text-color);
}

.review__item-page h6 {
    font-size: 1.1rem;
    color: var(--text-color);
}

.review__item-page span i {
    font-size: 1.2rem;
    color: var(--secondary-color);
}

.review__item-page span {
    font-weight: 500;
}

.nav-item-page {
    cursor: pointer;
}

.timeline-page {
    border-left: 2px solid #db9e36;
}

.daydot-page {
    margin-left: -4.1px;
    font-size: 1.5rem;
    color: #db9e36;
}

.styled-list-page {
    list-style: disc inside;
    padding-left: 3rem;
}


/* For iPad (768px to 1024px width) */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    
}

/* For mobile devices (up to 768px width) */
@media only screen and (max-width: 768px) {
    .tour__extra-details-page {
        flex-wrap: wrap;
        row-gap: 1rem;
    }

    .tour__extra-details-page span {
        font-size: 0.9rem;
    }

    .tour__extra-details-page span i {
        font-size: 1rem;
    }

    .room{
        width: 100%;
        flex-direction: column;
        flex-wrap: wrap;
    }
    .roomFormGroup{
        width: auto !important;
    }
}

