
.hero__subtitle img {
    width: 2.3rem;
    height: 2.3rem;
}

.hero__subtitle .section__subtilte {
    /* font-family: var(--bs-body-font-family); */
    text-align: start;
    font-size: 2.5rem;
}

.hero__content h1 {
    font-size: 3rem;
    font-weight: 600;
    margin-top: 1rem;
    margin-bottom: 2rem;
    color: var(--heading-color);
}

.hero__content h1 .highlight {
    color: var(--secondary-color);
}

.hero__content h2 .highlight {
    font-weight: 700;
    /* font-family: var(--mea-font-name); */
    color: var(--secondary-color);
}

.hero__content p {
    font-size: 1.1rem;
    color: var(--text-color);
    line-height: 2rem;
}

.hero__img-box img,
.hero__img-box video {
    width: 100%;
    height: 350px;
    border-radius: 20px;
    border: 1px solid var(--secondary-color);
    object-fit: cover;
}

.country__btn {
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 30%;
    border-radius: 5px;
    background: var(--secondary-color) !important;
    cursor: pointer;
    padding: 0.2rem 0.5rem !important;
}

.country__btn a {
    text-decoration: none;
    font-size: 1.5rem;
    color: #fff;
}

.country__btn a:hover {
    color: #fff;
}

.hero__content {
    padding-top: 3.5rem;
}

.hero__img-box {
    padding-top: 2rem;
    position: relative;
}

.services__subtitle {
    font-size: 2.2rem;
    font-weight: 500;
    color: #ee6e6e;
    /* font-family: var(--subtitle-font-name); */
}

.services__title {
    font-size: 2.5rem;
    font-weight: 500;
}

.featured__tour-title {
    text-align: center;
    font-size: 2rem;
    font-weight: 500;
}

.experience__content h2 {
    font-size: 2rem;
    margin-top: 1rem;
    font-weight: 500;
}

.experience__content p {
    font-size: 1.1rem;
    color: var(--text-color);
    margin-top: 1rem;
}

.counter__wrapper {
    margin-top: 2.5rem;
}

.counter__box span {
    width: 70px;
    height: 70px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--primary-color);
    color: #fff;
    font-size: 1.6rem;
    font-weight: 600;
    border-radius: 10px 5px 10px 5px;
}

.counter__box h6 {
    font-size: 1.1rem;
    margin-top: 0.7rem;
    color: var(--text-color);
}

.experience__img img {
    width: 90%;
}

.galley__title {
    font-size: 2.3rem;
    margin-bottom: 2.5rem;
    margin-top: 1rem;
}

.masonry__img {
    transition: .3s;
}

.masonry__img:hover {
    transform: scale(1.1);
}

.testimonial__title {
    text-align: center;
    font-size: 2.3rem;
    margin-top: 1rem;
}

.section__subtilte {
    /* font-family: var(--font-name); */
    font-size: 2.5rem;
    font-weight: 500;
    text-align: center;
    color: #00bedc;
}

.tripAdvisorImage{
    max-width: 15%;
}

#TA_cdsratingsonlynarrow318{
    display: flex;
    align-items: center;
    justify-content: center;
}
#CDSRATINGWIDGET318{
    padding: 1rem;
    font-size: larger;
}
#TA_cdsratingsonlynarrow318, #CDSRATINGWIDGET318 {
    width: 35%;
}

@media only screen and (max-width: 992px) {
    .hero__content h1 {
        font-size: 2.7rem;
    }

    .hero__img-box {
        /* display: none; */

        display: block;
    }

    .country__btn {
        left: 40%;
        width: 10rem;
        height: 3.5rem;
    }


    .hero__video-box {
        display: block;
    }

    .hero__subtitle .section__subtilte {
        font-size: 3rem;
    }

    .hero__content {
        padding-top: 1rem;
    }

    .goog-te-gadget-icon {
        display: none;
    }

    /* 
    .logo img {
        width: 50%;
    } */

    .services__title,
    .featured__tour-title,
    .galley__title,
    .testimonial__title,
    .experience__content h2 {
        font-size: 2rem;
    }

    .experience__img {
        margin-top: 2rem;
    }

    .tripAdvisorImage{
        max-width: 60%;
    }
    #TA_cdsratingsonlynarrow318, #CDSRATINGWIDGET318 {
        width: 75%;
    }
}

@media only screen and (max-width: 576px) {
    .hero__content h1 {
        font-size: 1.8rem;
    }

    .country__btn {
        left: 30%;
        width: 10rem;
        height: 3.5rem;
    }

    .carousel-item img {
        height: calc(-100px + 60vh) !important;
    }

    .hero__subtitle .section__subtilte {
        font-size: 2rem;
    }


    /* .logo img {
        width: 45%;
    } */

    .header {
        height: 50px;
        padding-top: 0.6rem;
    }

    section {
        padding: 8px 0px;
    }


    .services__title,
    .featured__tour-title,
    .galley__title,
    .testimonial__title,
    .experience__content h2 {
        font-size: 1.5rem;
    }
}

.box {
    --mask:
        radial-gradient(67.08px at 50% calc(100% - 90px), #000 99%, #0000 101%) calc(50% - 60px) 0/120px 100%,
        radial-gradient(67.08px at 50% calc(100% + 60px), #0000 99%, #000 101%) 50% calc(100% - 30px)/120px 100% repeat-x;
    -webkit-mask: var(--mask);
    mask: var(--mask);
    box-shadow: 0 -10px 20px -5px rgba(115, 115, 115, 0.75);
}