.page404-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.error-container {
    text-align: center;
}

.error-container h1 {
    font-size: 5rem;
    margin-bottom: 1rem;
}

.error-container p {
    font-size: 1.5rem;
    margin-bottom: 2rem;
}

.button{
    background-color: #db9e36 !important;
    border-color: #db9e36 !important;
    font-weight: bold !important;
}