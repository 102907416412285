.pagination span {
    width: 2rem;
    height: 2rem;
    padding: 5px;
    border-radius: 50px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--secondary-color);
    color: var(--heading-color);
    font-size: 1.1rem;
    cursor: pointer;
    font-weight: 700;
}

.active__page {
    background-color: var(--secondary-color);
    color: #fff !important;
}

.heroSection {
    /* background: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)),
        url('../assets/images/v3.jpg') no-repeat center center; */
    background-size: cover !important;
    height: 70vh;
    color: #fff;
    position: relative;
}

.heroSection .top {
    padding-top: 20vh;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.heroSection h1 {
    font-size: 4rem;
    padding-bottom: 1rem;
}

.heroSection h2 {
    font-size: 1.4rem;
    text-align: center;
}

.heroSection h1,
.heroSection h2,
.buttonBookNow,
.heroSection center {
    color: white !important;
    font-family: cursive;
}

.buttonBookNow {
    background-color: #db9e36 !important;
    border-color: #db9e36 !important;
    font-weight: bold !important;
    /* width: 20rem !important;
    margin-top: 4rem; */
}

.heroSection center {
    font-size: 3rem;
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
}

.overviewContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 1rem;
    border-radius: 15px;
    background-color: #D8E8DE;
}

.overviewContainer .left {
    flex: 2;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    padding-bottom: 2rem;
}

.overviewContainer .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
    padding: 10px;
}

.progressGroup {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: end;
}

.progress {
    height: 20% !important;
    background-color: #f4d39f !important;
}

.progress-bar {
    background-color: #db9e36 !important;
}

.CreateCard {
    /* background-color: #db9e36; */
    background-image: url('../assets/images/createTour.jpg');
    background-size: cover;
    height: 427px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;

    .buttonCreateTour {
        background-color: white !important;
        border-color: white !important;
        color: #db9e36;
        font-weight: bold !important;
        font-size: larger;
        /* width: 20rem !important; */
        /* margin-top: 4rem; */
    }
}

/* Media Queries */
/* Mobile Devices (up to 767px) */
@media only screen and (max-width: 767px) {
    .heroSection {
        height: 50%;
    }

    .heroSection h1 {
        font-size: 3rem;
    }

    .heroSection h2 {
        font-size: 1.8rem;
    }

    .heroSection .buttonBookNow {
        width: 5rem;
        margin-top: 2rem;
        margin-bottom: 0;
    }

    .heroSection center {
        display: none;
    }

    .overviewContainer {
        flex-direction: column;
        box-sizing: border-box;
        align-items: start;
        justify-content: start;
        padding-bottom: 0rem !important;
    }

    .overviewContainer .left {
        padding-bottom: 1rem;
        justify-content: start;
        padding-bottom: 4rem;
    }

    .overviewContainer .left p {
        padding-top: 0.5rem;

    }

    .overviewContainer .right {
        display: none;
    }
}

/* Tablets (768px to 1023px) */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .heroSection h1 {
        font-size: 3rem;
    }

    .heroSection h2 {
        font-size: 1.3rem;
    }

    .heroSection .buttonBookNow {
        width: 5rem;
        margin-top: 0.5rem;
    }

    .heroSection center {
        display: none;
    }

    .overviewContainer {
        padding-bottom: 0rem !important;
    }

    .overviewContainer .left {
        padding-bottom: 4rem;
    }

    .overviewContainer .left p {
        padding-top: 0.5rem;

    }

    .overviewContainer .right {
        display: none;
    }
}

/* Desktops and Laptops (1024px and above) */
@media only screen and (min-width: 1024px) {
    .heroSection h1 {
        font-size: 6rem;
    }

    .heroSection h2 {
        font-size: 1.6rem;
    }
}